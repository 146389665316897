import React from "react"
import { Link } from "gatsby"

import Logo from "../../images/be-logo.png"

const Header = () => (
  <header className="h3-l pv3 pv4-l content-box">
    <div className="mw-beskar center ph3 ph4-l flex items-end items-center-l justify-between h-100">
      <div className="logo">
        <Link className="db" to="/">
          <img className="h4" src={Logo} alt="beskar logo" />
        </Link>
      </div>
      <div className="links items-end items-center-l h-100 flex justify-between w-20">
        <Link className="link db" to="/">
          <h3 className="mb3 f5 lh-title">Values</h3>
        </Link>
        <Link className="link db" to="/people">
          <h3 className="mb3 f5 lh-title">People</h3>
        </Link>
        <Link className="link db" to="/contact">
          <h3 className="mb3 f5 lh-title">Contact</h3>
        </Link>
      </div>
    </div>
  </header>
)

export default Header
