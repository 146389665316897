import React from "react"
import Helmet from "react-helmet"

import Header from "../components/Header"
import Footer from "../components/Footer"
import "./fonts.css"
import "./beskar.css"
import "tachyons/"

const TemplateWrapper = ({ children }) => (
  <div className="flex flex-column min-vh-100">
    <Helmet
      title="Beskar - your scale partner"
      meta={[
        {
          name: "description",
          content: "We help you scale your team, processes and software"
        }
      ]}
    />
    <Header />
    <main className="flex-auto">{children}</main>
    <Footer />
  </div>
)

export default TemplateWrapper
