import React from "react"
import { Link } from "gatsby"

import LinkedinIcon from "../../icons/linkedin.svg"
import GithubIcon from "../../icons/github.svg"

const Footer = () => (
  <footer className="h3-l pt5 content-box">
    <div className="mw-beskar center ph3 ph4-l flex-l items-center justify-end h-100">
      <div className="icons justify-between items-center flex w-10 justify-around">
        <Link
          className="db"
          to="https://www.linkedin.com/company/beskar-technologies"
        >
          <img
            className="w2"
            src={LinkedinIcon}
            alt="link to beskar LinkedIn profile"
          />
        </Link>
        <Link className="db" to="https://gitlab.com/beskar-tech">
          <img
            className="w2"
            src={GithubIcon}
            alt="link to beskar Github profile"
          />
        </Link>
      </div>
    </div>
  </footer>
)

export default Footer
